@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

:root {
  font-family: Plus Jakarta Sans, Inter, system-ui, Avenir, Helvetica, Arial,
    sans-serif;
}
